

























































































































































































































































import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { Vehicle } from '@/api/interfaces/vehicle'
import TopBar from '@/components/organisms/o-top-bar.vue'
import store from '@/store'
import { QueryParams } from '@/store/api-plateform-utils'
import { usePagination, PaginationOptions } from '@/composables/pagination'
import Pagination from '@/components/base/Pagination.vue'
import { RawLocation } from 'vue-router'

export default defineComponent({
  setup (props, ctx) {
    const {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      }: PaginationOptions = usePagination(() => {
    return ctx.root.$store.state.vehicleList.totalItems
  }) as PaginationOptions

    return {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
    }
  },
  name: 'VehicleAssociateLogisticSessionList',
  components: {
    TopBar,
    Pagination,
  },
  data () {
    return {
      filters: {
        carModelSearched: '',
        carBrandSearched: '',
        referenceSearched: '',
        matriculationSearched: '',
        areaSearched: '',
      },
      title: this.$t(
        'vehicle_associate_logistic_session_list.list.title_header',
      ),
      headers: [
        {
          value: 'checkbox',
          sortable: false,
        },
        {
          text: this.$t(
            'vehicle_associate_logistic_session_list.list.headers.carModel',
          ),
          align: 'start',
          value: 'carModel.model',
        },
        {
          text: this.$t(
            'vehicle_associate_logistic_session_list.list.headers.carBrand',
          ),
          value: 'carBrand.brand',
        },
        {
          text: this.$t(
            'vehicle_associate_logistic_session_list.list.headers.reference',
          ),
          value: 'reference',
        },
        {
          text: this.$t(
            'vehicle_associate_logistic_session_list.list.headers.matriculation',
          ),
          value: 'matriculation',
          sortable: false,
        },
        {
          text: this.$t(
            'vehicle_associate_logistic_session_list.list.headers.area',
          ),
          value: 'area.name',
          sortable: false,
        },
      ],
      isSubmiting: false,
      checkedVehicles: [] as string[],
      loadedVehicles: new Map<string, Vehicle>(),
    }
  },
  computed: {
    ...mapState('vehicleList', {
      associateVehiclesList: 'list',
    }),
    ...mapState('area', {
      listAreas: 'list',
    }),
    ...mapState('logisticSessionForm', {
      logisticSession: 'logisticSession',
    }),
    ...mapGetters('navigation', {
      getLastRouteLogisticSession: 'getLastRouteLogisticSession',
    }),
    baseRequest (): QueryParams {
      const request: Record<string, unknown> = {
        carModel: this.filters.carModelSearched,
        carBrand: this.filters.carBrandSearched,
        reference: this.filters.referenceSearched,
        matriculation: this.filters.matriculationSearched,
        'inactiveUntilDate[before]': this.logisticSession.startAt,
        area: this.filters.areaSearched,
        ...this.paginationRequestParams,
      }
      return request as QueryParams
    },
    idLogisticSession (): string | null {
      return this.$route.params.idLogisticSession || null
    },
  },
  created () {
    if (this.getLastRouteLogisticSession === null) {
      const route: RawLocation = {
        name: 'LogisticSession Edit',
        params: {
          idLogisticSession: this.idLogisticSession as string,
        },
      }
      this.$router.push(route)
      return
    }
    this.checkedVehicles = this.logisticSession.vehicles.map(
      (v: Vehicle) => v['@id'],
    )
    this.logisticSession.vehicles.forEach((v: Vehicle) => {
      this.loadedVehicles.set(v['@id'] as string, v)
    })
    this.load(this.baseRequest).then(this.afterLoad)
  },
  watch: {
    filters: {
      handler () {
        this.paginationOptions.page = 1
        this.load(this.baseRequest).then(this.afterLoad)
      },
      deep: true,
    },
    paginationOptions: {
      handler () {
        this.load(this.baseRequest).then(this.afterLoad)
      },
      deep: true,
    },
  },
  methods: {
    afterLoad (loadedList: Vehicle[]) {
      loadedList.forEach((v: Vehicle) => {
        this.loadedVehicles.set(v['@id'] as string, v)
      })
    },
    ...mapActions('vehicleList', {
      load: 'load',
    }),
    ...mapActions('user', {
      sendSuccessMessage: 'sendSuccessMessage',
    }),
    ...mapMutations('logisticSessionForm', {
      setVehicles: 'setVehicles',
    }),
    associateCheckedVehiclesToLogisticSession () {
      this.isSubmiting = true

      const vehicles = [] as Vehicle[]
      this.checkedVehicles.forEach((vehicleIri: string): void => {
        if (this.loadedVehicles.has(vehicleIri)) {
          vehicles.push(this.loadedVehicles.get(vehicleIri) as Vehicle)
        }
      })
      this.setVehicles(vehicles)

      this.$router.push(this.getLastRouteLogisticSession)
    },
  },
  beforeRouteEnter (to, from, next) {
    store.commit('navigation/addRoute', to)
    next()
  },
  beforeRouteLeave (to, from, next) {
    if (to.name !== 'Vehicle Create') {
      store.commit('navigation/removeRoute')
    }
    next()
  },
})
